<template>
  <div class="card-view my-2">
    <div class="card-header">
      <router-link
        :to="{
          name: 'SingleSeason',
          params: { id: tvID, season: season.season_number },
        }"
      >
        <img
          v-if="season.poster_path"
          class="img-fluid w-100"
          :src="size + season.poster_path"
          :title="season.name"
          :alt="season.name"
        />
        <img
          v-else
          class="img-fluid w-100"
          src="../../assets/no_image.jpg"
          :title="season.name"
          :alt="season.name"
        />
      </router-link>
    </div>
    <div class="card-movie-content">
      <div class="card-movie-content-head">
        <router-link
          :to="{
            name: 'SingleSeason',
            params: { id: tvID, season: season.season_number },
          }"
          class="d-block text-center"
          v-text="season.name"
        ></router-link>
        <div class="ratings">
          <span v-text="season.episode_count"></span> قسمت
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardViewTvSeasons",
  props: {
    season: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    tvID: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped></style>
