<template>
  <div class="d-block m-2 text-center">
    <img
      src="../../assets/spinner.svg"
      alt="در حال بارگزاری"
      title="در حال بارگزاری"
      width="100"
      height="100"
    />
  </div>
</template>

<script>
export default {
  name: "spinner",
};
</script>

<style scoped></style>
