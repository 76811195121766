<template>
  <div class="card mb-4" v-if="this.$store.state.getMovie.status.tvShowLoading">
    <div class="card-header bg-color-primary-1">
      <h2 class="text-center text-white my-2">فصل های {{ tv.name }}</h2>
      <p class="text-center text-white m-0">
        همه فصل های این نمایش شبکه تلوزیونی را در زیر مشاهده نمایید.
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          class="col-sm-6 col-md-4 col-lg-3"
          v-for="season in tv.seasons"
          :key="season.id"
        >
          <cardViewTvSeasons
            :season="season"
            :tvID="tv.id"
            :size="imageSizeMovie('w342')"
          ></cardViewTvSeasons>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else>
    <spinner />
  </div>
</template>

<script>
import cardViewTvSeasons from "@/components/cardView/cardViewTvSeason";
import Spinner from "@/components/template/spinner";
export default {
  name: "seasonTv",
  components: {
    Spinner,
    cardViewTvSeasons,
  },
  props: {
    tv: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
